<template>
    <div class="action-alimi-basic card">
        <div v-if="content.url" class="img-cover" v-img-cover="content.url" @click="onClickAlimiImage" />
        <div class="title" v-html="content.title" />
        <div class="body">
            <div ref="msg" class="msg" :class="{ expanded: expanded }" v-html="content.msg" />
            <div
                v-if="shouldShowLoadMore"
                @click="expand"
                class="btn-load-more flex-wrap"
                v-html="$translate('MORE')"
            />
            <button v-if="content.cta" @click="onClickAlimiCta" class="btn btn-alimi" v-html="content.cta" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionAlimiBasic',
    props: ['message'],
    data: () => ({
        shouldShowLoadMore: false,
        dom: null,
        expanded: false,
    }),
    mounted() {
        this.dom = this.$refs.msg
        if (!this.dom) return

        // 4줄까지의 scrollHeight = 76px
        this.shouldShowLoadMore = this.dom.scrollHeight > 76
    },
    computed: {
        content() {
            return this.$mustParse(this.message.content.replace(/=>/g, ':')) || {}
        },
    },
    methods: {
        onClickAlimiImage() {
            if (this.content.action.includes('http://') || this.content.action.includes('https://'))
                this.onClickAlimiCta()
        },
        async onClickAlimiCta() {
            if (!this.content.action) return

            if (this.content.action.includes('http://') || this.content.action.includes('https://')) {
                if (this.$store.getters.isMobile) {
                    this.$nativeBridge.postMessage({
                        action: 'openInAppBrowser',
                        value: this.content.action,
                    })
                } else {
                    window.open(this.content.action)
                }
            } else if (this.content.action === 'PremiumSurveyStartPage') {
                this.$toast.error('회원이 작성하면 채팅방에 메시지가 옵니다')
            } else {
                this.$router.push({ name: this.content.action })
            }
        },
        expand() {
            this.expanded = true
            this.shouldShowLoadMore = false
        },
    },
}
</script>
